  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    height: 80vh;
    background: #fff;
    padding-top: 82px;
  }

  #hero h1 {
    margin: 0;
    font-size: 48px;
    text-align: start;
    font-weight: 700;
    line-height: 56px;
    color: #3e4450;
  }

  #hero h2 {
    text-align: start;
    color: #858ea1;
    margin: 10px 0 30px 0;
    font-size: 24px;
  }

  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 30px 10px 30px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: #3e8e41;
    box-shadow: 0 8px 28px rgba(22, 223, 126, 0.45);
  }

  #hero .btn-get-started:hover {
    background: #16df7e;
    box-shadow: 0 8px 28px rgba(32, 251, 50, 0.45);
  }

  #hero .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }

  @media (max-width: 991px) {
    #hero {
      height: 100vh;
    }

    #hero .animated {
      -webkit-animation: none;
      animation: none;
    }

    #hero .hero-img {
      text-align: center;
    }

    #hero .hero-img img {
      width: 50%;
    }
  }

  @media (max-width: 768px) {
    #hero {
      height: auto;
    }

    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }

    #hero h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }

    #hero .hero-img img {
      width: 70%;
    }
  }

  @media (max-width: 575px) {
    #hero .hero-img img {
      width: 80%;
    }
  }

  @-webkit-keyframes up-down {
    0% {
      transform: translateY(10px);
    }

    100% {
      transform: translateY(-10px);
    }
  }

  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }

    100% {
      transform: translateY(-10px);
    }
  }

/*--------------------------------------------------------------
# Featured Services
--------------------------------------------------------------*/
.featured-services {
  padding: 0;
}

.featured-services .icon-box {
  padding: 20px;
  color: #5f687b;
  background: #f9f9fa;
  border-radius: 4px;
  border: 1px solid #f9f9fa;
  transition: 0.3s;
  height: 100%;
}

.featured-services .icon {
  margin: 0 0 15px 0;
}

.featured-services .icon i {
  color: #16df7e;
  font-size: 36px;
}

.featured-services .title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
}

.featured-services .title a {
  color: #5f687b;
  transition: 0.3s;
}

.featured-services .icon-box:hover {
  border-color: #eaecef;
}

.featured-services .icon-box:hover .title a {
  color: #16df7e;
}

.featured-services .description {
  line-height: 24px;
  font-size: 14px;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 32px;
  color: #5f687b;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #16df7e;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding-top: 0;
}

.counts .counters span {
  font-size: 48px;
  display: block;
  color: #16df7e;
  font-weight: 700;
}

.counts .counters p {
  padding: 0;
  margin: 0 0 20px 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .feature-box {
  padding: 24px 20px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
  height: 100%;
}

.features .feature-box h3 {
  font-size: 18px;
  color: #012970;
  font-weight: 700;
  margin: 0;
}

.features .feature-box i {
  line-height: 0;
  background: #ecf3ff;
  padding: 4px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  transition: 0.3s;
}

.features .feature-box:hover i {
  background: #4154f1;
  color: #fff;
}

.features .feture-tabs {
  margin-top: 120px;
}

.features .feture-tabs h3 {
  color: #012970;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .features .feture-tabs h3 {
    font-size: 28px;
  }
}

.features .feture-tabs .nav-pills {
  border-bottom: 1px solid #eee;
}

.features .feture-tabs .nav-link {
  background: none;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  color: #012970;
  padding: 12px 0;
  margin-right: 25px;
  margin-bottom: -2px;
  border-radius: 0;
}

.features .feture-tabs .nav-link.active {
  color: #4154f1;
  border-bottom: 3px solid #4154f1;
}

.features .feture-tabs .tab-content h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  color: #012970;
}

.features .feture-tabs .tab-content i {
  font-size: 24px;
  line-height: 0;
  margin-right: 8px;
  color: #4154f1;
}

.features .feature-icons {
  margin-top: 120px;
}

.features .feature-icons h3 {
  color: #012970;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .features .feature-icons h3 {
    font-size: 28px;
  }
}

.features .feature-icons .content .icon-box {
  display: flex;
}

.features .feature-icons .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
  color: #012970;
}

.features .feature-icons .content .icon-box i {
  font-size: 44px;
  line-height: 44px;
  color: #0245bc;
  margin-right: 15px;
}

.features .feature-icons .content .icon-box p {
  font-size: 15px;
  color: #848484;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .service-box {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  height: 100%;
  padding: 60px 30px;
  text-align: center;
  transition: 0.3s;
  border-radius: 5px;
}

.services .service-box .icon {
  font-size: 36px;
  padding: 40px 20px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 25px;
  display: inline-block;
  line-height: 0;
  transition: 0.3s;
}

.services .service-box h3 {
  color: #444444;
  font-weight: 700;
}

.services .service-box .read-more {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 20px;
}

.services .service-box .read-more i {
  line-height: 0;
  margin-left: 5px;
  font-size: 18px;
}

.services .service-box.blue {
  border-bottom: 3px solid #2db6fa;
}

.services .service-box.blue .icon {
  color: #2db6fa;
  background: #dbf3fe;
}

.services .service-box.blue .read-more {
  color: #2db6fa;
}

.services .service-box.blue:hover {
  background: #2db6fa;
}

.services .service-box.orange {
  border-bottom: 3px solid #f68c09;
}

.services .service-box.orange .icon {
  color: #f68c09;
  background: #fde3c4;
}

.services .service-box.orange .read-more {
  color: #f68c09;
}

.services .service-box.orange:hover {
  background: #f68c09;
}

.services .service-box.green {
  border-bottom: 3px solid #08da4e;
}

.services .service-box.green .icon {
  color: #08da4e;
  background: #cffddf;
}

.services .service-box.green .read-more {
  color: #08da4e;
}

.services .service-box.green:hover {
  background: #08da4e;
}

.services .service-box.red {
  border-bottom: 3px solid #e9222c;
}

.services .service-box.red .icon {
  color: #e9222c;
  background: #fef7f8;
}

.services .service-box.red .read-more {
  color: #e9222c;
}

.services .service-box.red:hover {
  background: #e9222c;
}

.services .service-box.purple {
  border-bottom: 3px solid #b50edf;
}

.services .service-box.purple .icon {
  color: #b50edf;
  background: #f8e4fd;
}

.services .service-box.purple .read-more {
  color: #b50edf;
}

.services .service-box.purple:hover {
  background: #b50edf;
}

.services .service-box.pink {
  border-bottom: 3px solid #f51f9c;
}

.services .service-box.pink .icon {
  color: #f51f9c;
  background: #feecf7;
}

.services .service-box.pink .read-more {
  color: #f51f9c;
}

.services .service-box.pink:hover {
  background: #f51f9c;
}

.services .service-box:hover h3,
.services .service-box:hover p,
.services .service-box:hover .read-more {
  color: #fff;
}

.services .service-box:hover .icon {
  background: #fff;
}

/*--------------------------------------------------------------
# Recent Blog Posts
--------------------------------------------------------------*/
.recent-blog-posts .post-box {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  padding: 30px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.recent-blog-posts .post-box .post-img {
  overflow: hidden;
  margin: -30px -30px 15px -30px;
  position: relative;
}

.recent-blog-posts .post-box .post-img img {
  transition: 0.5s;
}

.recent-blog-posts .post-box .post-date {
  font-size: 16px;
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
  display: block;
  margin-bottom: 10px;
}

.recent-blog-posts .post-box .post-title {
  font-size: 24px;
  color: #012970;
  font-weight: 700;
  margin-bottom: 18px;
  position: relative;
  transition: 0.3s;
}

.recent-blog-posts .post-box .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
}

.recent-blog-posts .post-box .readmore i {
  line-height: 0;
  margin-left: 4px;
  font-size: 18px;
}

.recent-blog-posts .post-box:hover .post-title {
  color: #4154f1;
}

.recent-blog-posts .post-box:hover .post-img img {
  transform: rotate(6deg) scale(1.2);
}
