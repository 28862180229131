.evemt-li{
  list-style: none !important;
  margin: 0px 10px;
}


.eventbox {
  background-size: cover;
  background-position: center;
  height: 400px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .eventbox {
    top: 2%;
    width: 97%;
    height: 96%;
  }
}

@media (max-width: 767px) {
  .eventbox {
    height: 400px!important;
  }
}

@media (max-width: 380px) {
  .eventbox {
    background-size: cover;
    background-position: center;
    height: 700px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.user-icon{
  width: 45px; 
  height: 45px;
}